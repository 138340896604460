import { TimeZone } from "@marathon/common/helpers/timeZoneHelper";
import { Coordinates, getDistanceInMiles } from "../helpers/mapHelper";
import { shouldBeUnreachable } from "../helpers/typesHelper";
import { Customer, LeadOrigin } from "./Customer";
import { CsatRequestConfiguration } from "./Configuration";
import { GoogleAddress } from "../api/GooglePlace";

enum HubTimeZoneGroup {
    AllPacific = "all-pacific-hubs",
    AllMountain = "all-mountain-hubs",
    AllCentral = "all-central-hubs",
    AllArizona = "all-arizona-hubs",
    AllEastern = "all-eastern-hubs",
    AllHubs = "all-hubs"
}

export const VCARD_MEDIA_TYPE = "text/x-vcard";

enum HubStatus {
    Active = "active",
    Inactive = "inactive"
}

interface HubData {
    acronym: string,
    full_address: string,
    name: string,
    phone_number: string,
    place_id: string,
    vertices: Coordinates[],
    coordinates: Coordinates,
    pricing_preset_id: string
    time_zone: TimeZone,
    default_maximum_distance?: number,
    next_day_maximum_distance?: number,
    default_maximum_drive_time?: number,
    next_day_maximum_drive_time?: number,
    prices_include_tax?: true,
    first_time_customer_discount?: {
        value: number,
        is_enabled: boolean
    }
    goal: {
        value: number,
        thismn: number
    },
    square_location_id: string,
    vcard_url: string,
    hide_from_online_booking?: true,
    google_csat_feedback_url: string,
    extra_csat_feedback_urls?: CsatFeedbackURL[],
    hub_group: GeofenceGroup,
    is_barkbus_legacy?: true,
    disable_pull_forward?: true,
    status: HubStatus
}

interface Hub extends HubData {
    id: string
}

interface CsatFeedbackURL {
    origin: LeadOrigin,
    url: string
}

class Hub {
    constructor(id: string, data: HubData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): HubData {
        const { id, ...data } = this;
        return data;
    }
    getFormattedPhoneNumber() {
        const match = this.phone_number.match(/(\d{3})(\d{3})(\d{4})/);
        if (!match) {
            return this.phone_number;
        }
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    static defaultSalesGoal() {
        return {
            value: 0,
            thismn: 1
        };
    }
    getDistanceFromHubInMiles(destinationCoordinates: Coordinates) {
        return getDistanceInMiles(this.coordinates, destinationCoordinates);
    }
    getCsatFeedbackUrl(customer: Customer, configuration: CsatRequestConfiguration, hubs: Hub[]) {
        const forcedHub = hubs.find(x => x.id === configuration.forced_feedback_hub_id);
        const toMatchByOriginUrls = [
            ...this.extra_csat_feedback_urls || [],
            { origin: LeadOrigin.google, url: this.google_csat_feedback_url },
            { origin: LeadOrigin.googleAd, url: this.google_csat_feedback_url }
        ];
        const matchedByOriginUrl = toMatchByOriginUrls.find(x =>
            x.origin === customer.leadOriginFromUtmSource() ||
            x.origin === customer.lead_info?.origin
        )?.url;
        return (
            matchedByOriginUrl ||
            forcedHub?.google_csat_feedback_url ||
            this.google_csat_feedback_url
        );
    }
    isActive() {
        return this.status === HubStatus.Active;
    }
    static formatFullAddress(googleAddress: GoogleAddress) {
        return `${googleAddress.address1}, ${googleAddress.city}, ${googleAddress.state} ${googleAddress.zip}`;
    }
    static customCsatLeadOrigins() {
        return [LeadOrigin.yelp, LeadOrigin.nextdoor, LeadOrigin.thumbtack];
    }
    static get group() {
        return {
            name: (group: HubTimeZoneGroup) => {
                return (
                    group
                        .split("-")
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(" ")
                );
            },
            timeZone: (group: HubTimeZoneGroup) => {
                switch (group) {
                    case HubTimeZoneGroup.AllHubs:
                        return TimeZone.PacificTime;
                    case HubTimeZoneGroup.AllPacific:
                        return TimeZone.PacificTime;
                    case HubTimeZoneGroup.AllMountain:
                        return TimeZone.MountainTime;
                    case HubTimeZoneGroup.AllCentral:
                        return TimeZone.CentralTime;
                    case HubTimeZoneGroup.AllArizona:
                        return TimeZone.ArizonaTime;
                    case HubTimeZoneGroup.AllEastern:
                        return TimeZone.EasternTime;
                    default:
                        shouldBeUnreachable(group);
                }
            }
        };
    }
    static get geofenceGroup() {
        return {
            name: (group: GeofenceGroup) => {
                if (group.length === 3) {
                    return group.toUpperCase();
                }
                else {
                    return (
                        group
                            .split("-")
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(" ")
                    );
                }
            },
            zoom: (group: GeofenceGroup) => {
                switch (group) {
                    case GeofenceGroup.losAngeles:
                        return 8.5;
                    case GeofenceGroup.orangeCounty:
                        return 9;
                    case GeofenceGroup.sanDiego:
                        return 9;
                    case GeofenceGroup.bayArea:
                        return 8.5;
                    case GeofenceGroup.centralValley:
                        return 8.25;
                    case GeofenceGroup.texas:
                        return 9;
                    case GeofenceGroup.houston:
                        return 9;
                    case GeofenceGroup.phoenix:
                        return 9;
                    case GeofenceGroup.atlanta:
                        return 10.5;
                    case GeofenceGroup.charlotte:
                        return 9;
                    case GeofenceGroup.dmv:
                        return 9;
                    case GeofenceGroup.colorado:
                        return 8;
                    case GeofenceGroup.southFlorida:
                        return 8;
                    case GeofenceGroup.triState:
                        return 9;
                    case GeofenceGroup.chicago:
                        return 9;
                    default:
                        shouldBeUnreachable(group);
                }
            },
            center: (group: GeofenceGroup) => {
                switch (group) {
                    case GeofenceGroup.losAngeles:
                        return { lat: 33.9122, lng: -118.5737 };
                    case GeofenceGroup.orangeCounty:
                        return { lat: 33.7175, lng: -117.8311 };
                    case GeofenceGroup.sanDiego:
                        return { lat: 33.0157, lng: -117.1611 };
                    case GeofenceGroup.bayArea:
                        return { lat: 37.7749, lng: -121.9994 };
                    case GeofenceGroup.centralValley:
                        return { lat: 38.1391, lng: -121.2269 };
                    case GeofenceGroup.texas:
                        return { lat: 32.9267, lng: -96.8870 };
                    case GeofenceGroup.houston:
                        return { lat: 29.7604, lng: -95.5998 };
                    case GeofenceGroup.phoenix:
                        return { lat: 33.3884, lng: -111.8500 };
                    case GeofenceGroup.atlanta:
                        return { lat: 33.8290, lng: -84.3880 };
                    case GeofenceGroup.charlotte:
                        return { lat: 35.2796, lng: -80.8382 };
                    case GeofenceGroup.dmv:
                        return { lat: 38.8951, lng: -77.2364 };
                    case GeofenceGroup.colorado:
                        return { lat: 39.7392, lng: -104.9903 };
                    case GeofenceGroup.southFlorida:
                        return { lat: 26.117018, lng: -80.1374448 };
                    case GeofenceGroup.triState:
                        return { lat: 40.7128, lng: -74.0060 };
                    case GeofenceGroup.chicago:
                        return { lat: 41.8781, lng: -87.6298 };
                    default:
                        shouldBeUnreachable(group);
                }
            },
            timeZone: (group: GeofenceGroup) => {
                switch (group) {
                    case GeofenceGroup.losAngeles:
                    case GeofenceGroup.orangeCounty:
                    case GeofenceGroup.sanDiego:
                    case GeofenceGroup.bayArea:
                    case GeofenceGroup.centralValley:
                        return TimeZone.PacificTime;
                    case GeofenceGroup.texas:
                    case GeofenceGroup.houston:
                    case GeofenceGroup.chicago:
                        return TimeZone.CentralTime;
                    case GeofenceGroup.phoenix:
                        return TimeZone.ArizonaTime;
                    case GeofenceGroup.charlotte:
                    case GeofenceGroup.dmv:
                    case GeofenceGroup.atlanta:
                    case GeofenceGroup.southFlorida:
                    case GeofenceGroup.triState:
                        return TimeZone.EasternTime;
                    case GeofenceGroup.colorado:
                        return TimeZone.MountainTime;
                    default:
                        shouldBeUnreachable(group);
                }
            }
        };
    }
    static get evaluate() {
        return {
            addressChanged: (hubBefore: HubData, hubNow: HubData) => {
                return hubBefore.full_address !== hubNow.full_address;
            }
        };
    }
}

enum GeofenceGroup {
    losAngeles = "los-angeles",
    orangeCounty = "orange-county",
    sanDiego = "san-diego",
    bayArea = "bay-area",
    centralValley = "central-valley",
    colorado = "colorado",
    texas = "texas",
    houston = "houston",
    phoenix = "phoenix",
    charlotte = "charlotte",
    dmv = "dmv",
    atlanta = "atlanta",
    southFlorida = "south-florida",
    triState = "tri-state",
    chicago = "chicago"
}

export { Hub, HubTimeZoneGroup, GeofenceGroup, CsatFeedbackURL, HubStatus };
export type { HubData };